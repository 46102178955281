import firebase from 'firebase/app' // doing import firebase from 'firebase' or import * as firebase from firebase is not good practice.
// import firebase from 'firebase/compat/app';
// import { firebase } from 'firebase/app';
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore';
import Axios from 'axios'

// Initialize Firebase
let config = {
  // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  apiKey: "AIzaSyCe-zYt5D58oDzdaHAWd7NeQagjZvhL7yQ",
  authDomain: "it-spar.firebaseapp.com",
  projectId: "it-spar",
  storageBucket: "it-spar.appspot.com",
  messagingSenderId: "45562978573",
  appId: "1:45562978573:web:06cfea9670d49bcb479044",
  measurementId: "G-ZWKFZ972FW"
}

firebase.initializeApp(config)

const db = firebase.firestore()

export { Axios, db }