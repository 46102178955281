import * as React from "react";
import {colors} from "../../utils";

const SiteLogo = ({
  width = 70,
  primary = colors.primary,
  secondary = colors.secondary,
  ...props
}) => (
  <svg
    width={width}
    height={width * 1.10727}
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd"
    }}
    viewBox="0 0 4025 4457"
    {...props}
  >
    <g id="Layer_x0020_1">
      <g id="_370922344">
        <path
          d="M3507 167c41-98 119-167 249-167s269 120 269 269c0 148-139 269-269 269s-208-69-249-167H2142v3090h1365c41-97 119-166 249-166s269 120 269 268c0 149-139 269-269 269s-208-69-249-167H517c-40 98-118 167-248 167-131 0-269-120-269-269 0-148 138-268 269-268 130 0 208 69 248 166h1421V371H517c-40 98-118 167-248 167C138 538 0 417 0 269 0 120 138 0 269 0c130 0 208 69 248 167h2990z"
          style={{
            fill: secondary
          }}
        />
        <path
          className="fil1"
          style={{
            fill: primary
          }}
          d="M667 525c112 0 208 69 248 167h729v2082c98 40 167 119 167 249s-121 269-269 269-269-139-269-269 69-209 167-249V896H915c-40 98-136 167-248 167-149 0-269-121-269-269s120-269 269-269zM3403 525c-113 0-209 69-249 167h-729v2082c-98 40-167 119-167 249s121 269 269 269c149 0 269-139 269-269s-69-209-167-249V896h525c40 98 136 167 249 167 148 0 269-121 269-269s-121-269-269-269z"
        />
        <path
          d="M726 3856v592H587v-592h139zm499 0v117h-147v475H938v-475H791v-117h434zm696 130-118 40c-16-47-49-70-101-70-55 0-82 17-82 53 0 14 5 26 15 36 11 9 34 18 70 26 60 12 104 24 132 37 29 12 52 32 72 60 19 27 28 58 28 92 0 53-20 99-61 138-42 39-103 59-184 59-60 0-112-14-155-42-42-27-70-68-84-123l129-29c14 55 54 83 120 83 32 0 55-6 71-19 15-12 23-27 23-44s-7-30-21-40c-14-9-41-18-82-27-75-15-129-36-161-63s-48-67-48-119 19-97 58-133c38-36 90-54 155-54 116 0 191 47 224 139zm254 242v220h-141v-592h233c52 0 91 6 119 17 27 11 52 31 74 59 21 29 32 64 32 106 0 29-6 57-17 85-12 28-28 49-49 65s-41 27-61 32-51 8-93 8h-97zm-5-108h90c34 0 58-8 72-23 14-16 21-34 21-55 0-24-8-43-23-57-16-14-39-21-70-21h-90v156zm661-264 184 592h-149l-36-123h-191l-37 123h-122l190-592h161zm-159 360h125l-62-207-63 207zm553-2v234h-140v-592h260c48 0 87 6 116 17s53 32 72 61c19 30 28 64 28 100 0 71-34 122-102 153l109 261h-153l-91-234h-99zm0-251v150h85c27 0 48-2 60-6 13-3 24-12 34-25 10-14 15-29 15-47 0-16-5-31-15-44s-21-21-32-24c-12-3-34-4-65-4h-82z"
          style={{
            fill: secondary,
            fillRule: "nonzero"
          }}
        />
      </g>
    </g>
  </svg>
);

export const Logo = React.memo(SiteLogo);
