import {Icon} from "@iconify/react";
import React, {Fragment, useContext} from "react";
import "./Skills.scss";
import {Fade} from "react-reveal";
import {Col, Container, Row, UncontrolledTooltip} from "reactstrap";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import {skillsSection} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";

const Skills = () => {
  const {isDark} = useContext(StyleContext);
  // if (!skillsSection.display) {
  //   return null;
  // }
  return (
    skillsSection && (
      <Container className="text-center my-5 section section-lg">
        <h1 className="h1">{skillsSection.title}</h1>
        <p className="lead">{skillsSection.subTitle}</p>
        {skillsSection.data.map((section, index) => {
          return (
            <div
              key={`skillsSection-${index}`}
              className={isDark ? "dark-mode main" : "main"}
              id="skills"
            >
              <div className="skills-main-div">
                <div className="skills-image-div">
                  <Fade left duration={500}>
                    <DisplayLottie
                      animationData={section.lottieAnimationFile}
                    />
                  </Fade>
                </div>
                <div className="skills-text-div">
                  <Fade right duration={300}>
                    <h3
                      className={
                        isDark ? "dark-mode skills-heading" : "skills-heading"
                      }
                    >
                      {section.title}
                    </h3>
                    <div className="d-flex justify-content-center flex-wrap mb-2">
                      {section.softwareSkills.map((skill, i) => {
                        return (
                          <Fragment key={`softwareSkills-${i}`}>
                            <div
                              className="icon icon-lg icon-shape shadow-sm rounded-circle m-1"
                              id={skill.skillName.replace(/\s/g, "")}
                            >
                              <Icon
                                icon={skill.fontAwesomeClassname}
                                data-inline="false"
                              ></Icon>
                            </div>
                            <UncontrolledTooltip
                              delay={0}
                              placement="bottom"
                              target={skill.skillName.replace(/\s/g, "")}
                            >
                              {skill.skillName}
                            </UncontrolledTooltip>
                          </Fragment>
                        );
                      })}
                    </div>
                    <div>
                      {section.skills.map((skill, i) => {
                        return <p key={i}>{skill}</p>;
                      })}
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          );
        })}
      </Container>
    )
  );
};

export default Skills;
