import React, {useEffect, useState} from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
// import ScrollToTopButton from "./topbutton/Top";
import Profile from "../containers/profile/Profile";
import SplashScreen from "../containers/splashScreen/SplashScreen";
import {splashScreen} from "../portfolio";
import {StyleProvider} from "../contexts/StyleContext";
import {useLocalStorage} from "../hooks/useLocalStorage";
import ScrollToTopButton from "../containers/topbutton/Top";
import "../containers/Main.scss";
import {Fade} from "react-reveal";

const Main = () => {
  const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
  const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);
  const [isShowingSplashAnimation, setIsShowingSplashAnimation] =
    useState(true);

  useEffect(() => {
    if (splashScreen.enabled) {
      const splashTimer = setTimeout(
        () => setIsShowingSplashAnimation(false),
        splashScreen.duration
      );
      return () => {
        clearTimeout(splashTimer);
      };
    }
  }, []);

  const changeTheme = () => {
    setIsDark(!isDark);
  };

  return (
    <div className={isDark ? "dark-mode" : null}>
      <StyleProvider value={{isDark: isDark, changeTheme: changeTheme}}>
        {/* {isShowingSplashAnimation && splashScreen.enabled ? (
          <SplashScreen />
        ) : ( */}
          <>
            <Header />
            <Fade bottom duration={1000} distance="20px">
              <div className="main contact-margin-top" id="contact">
                <h1>Privacy Policy</h1>

                <h2>1. Collection of Information</h2>
                <p>
                  When you use our services at [Company Name], we may collect
                  certain personal information from you. This may include:
                </p>
                <ul>
                  <li>Name, email address, and contact information</li>
                  <li>
                    Demographic information such as age, gender, and location
                  </li>
                  <li>
                    Usage data and analytics, including IP address and device
                    information
                  </li>
                  <li>
                    Cookies and similar technologies for website functionality
                    and analysis
                  </li>
                </ul>

                <h2>2. Use of Information</h2>
                <p>
                  We use the collected information for the following purposes:
                </p>
                <ul>
                  <li>To provide and maintain our services</li>
                  <li>
                    To personalize your experience and improve customer service
                  </li>
                  <li>
                    To send periodic emails and updates related to our services
                  </li>
                  <li>
                    To analyze usage and trends to enhance and optimize our
                    offerings
                  </li>
                  <li>
                    To comply with legal obligations and protect against
                    fraudulent activities
                  </li>
                </ul>

                <h2>3. Data Sharing and Disclosure</h2>
                <p>
                  We may share your information with third parties in the
                  following cases:
                </p>
                <ul>
                  <li>With your consent or at your direction</li>
                  <li>
                    With service providers and partners who assist us in
                    delivering our services
                  </li>
                  <li>
                    When required by law or to protect our rights, safety, or
                    property
                  </li>
                  <li>
                    In connection with a merger, acquisition, or sale of assets
                  </li>
                </ul>

                <h2>4. Data Security</h2>
                <p>
                  We prioritize the security of your information and take
                  appropriate measures to protect it. However, please note that
                  no method of transmission over the internet or electronic
                  storage is 100% secure. We cannot guarantee absolute security
                  of your data.
                </p>

                <h2>5. Your Rights and Choices</h2>
                <p>You have the right to:</p>
                <ul>
                  <li>Access and update your personal information</li>
                  <li>Opt-out of receiving marketing communications</li>
                  <li>
                    Request the deletion of your personal information, subject
                    to legal requirements
                  </li>
                </ul>

                <h2>6. Third-Party Links</h2>
                <p>
                  Our services may contain links to third-party websites or
                  services. We are not responsible for the privacy practices or
                  content of such third parties. We encourage you to review
                  their privacy policies before providing any personal
                  information.
                </p>

                <h2>7. Changes to this Privacy Policy</h2>
                <p>
                  We reserve the right to update or modify this Privacy Policy
                  at any time. Any changes will be effective immediately upon
                  posting the revised version. We recommend checking this page
                  periodically to stay informed about our privacy practices.
                </p>

                <h2>8. Contact Us</h2>
                <p>
                  If you have any questions or concerns about our Privacy Policy
                  or the use of your personal information, please contact us at
                  [contact email address].
                </p>
              </div>
            </Fade>
            <Footer />
            <ScrollToTopButton />
          </>
        {/* )} */}
      </StyleProvider>
    </div>
  );
};

export default Main;
