import React from 'react';
import Main from "./containers/Main";
import './assets/styles/argon-design-system-react.css';
import { Route, Routes  } from 'react-router-dom';
import Support from "./pages/Support";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CancellationRefundPolicy from "./pages/CancellationRefundPolicy";



const App = () => {
  return (
      <Routes>
        <Route exact path="/" element={<Main/>} />
        <Route path="/support" element={<Support/>} />
        <Route path="/terms-of-service" element={<TermsOfService/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="/cancellation" element={<CancellationRefundPolicy/>} />
      </Routes>
  );
};

export default App;
