import React, { useState } from 'react'
import { Axios, db } from '../../firebaseConfig'
import './styled.scss'

const ContactForm = () => {
  const [formData, setFormData] = useState({
    "name": "",
    "email": "",
    "message": ""
    // "name": "Jaswant Dhayal",
    // "email": "test@gmail.com",
    // "message": "This is a test message"
})

  const updateInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }
  const handleSubmit = event => {
    event.preventDefault()
    sendEmail()
    setFormData({
      name: '',
      email: '',
      message: '',
    })
  }

  const sendEmail = () => {
    Axios.post(
      'https://us-central1-it-spar.cloudfunctions.net/submit',
      // "http://localhost:5000/it-spar/us-central1/submit",
      formData
    )
      .then(res => {
        if(res?.data?.isEmailSend){
          alert(`
          Your request has been submitted.
          We'll get back to you soon.

          Thanks & Regards
          IT Spar Team
          `)
        }
        db.collection('emails').add({
          name: formData.name,
          email: formData.email,
          message: formData.message,
          time: new Date(),
        }) .then(res => {
          console.log("res => ",res)
        })
        .catch(error => {
          console.log('error => ',error)
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Name"
          onChange={updateInput}
          value={formData.name || ''}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          onChange={updateInput}
          value={formData.email || ''}
          required
        />
        <textarea
          type="text"
          name="message"
          placeholder="Message"
          onChange={updateInput}
          value={formData.message || ''}
          required
        ></textarea>
        <button type="submit">Submit</button>
      </form>
    </>
  )
}

export default ContactForm