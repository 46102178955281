import React, {useContext} from "react";
import emoji from "react-easy-emoji";
import {Fade} from "react-reveal";
import Typewriter from "typewriter-effect";
import Button from "../../components/button/Button";
import "./Greeting.scss";

import {MainBanner} from "../../components/icons";
import StyleContext from "../../contexts/StyleContext";
import {greeting} from "../../portfolio";

export default function Greeting() {
  const {isDark} = useContext(StyleContext);
  if (!greeting.displayGreeting) {
    return null;
  }
  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1
                className={isDark ? "dark-mode greeting-text" : "greeting-text"}
              >
                {greeting.title}
                <span className="wave-emoji">{emoji("🚀")}</span>
              </h1>

              <div className="button-greeting-div">
                <Typewriter
                  options={{
                    strings: [
                      "Artificial Intelligence",
                      "Machine Learning",
                      "Mobile Application",
                      "Web Application",
                      "Desktop Softwares"
                    ],
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 10
                  }}
                />
              </div>
              <div className="contact-us-btn-main">
                <Button text="Contact Us" href="#contact" />
              </div>
            </div>
          </div>
          <div className="greeting-image-div">
            <MainBanner />
          </div>
        </div>
      </div>
    </Fade>
  );
}
