import React, {useEffect, useState} from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
// import ScrollToTopButton from "./topbutton/Top";
import Profile from "../containers/profile/Profile";
import SplashScreen from "../containers/splashScreen/SplashScreen";
import {splashScreen} from "../portfolio";
import {StyleProvider} from "../contexts/StyleContext";
import {useLocalStorage} from "../hooks/useLocalStorage";
import ScrollToTopButton from "../containers/topbutton/Top";
import "../containers/Main.scss";
import {Fade} from "react-reveal";

const Main = () => {
  const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
  const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);
  const [isShowingSplashAnimation, setIsShowingSplashAnimation] =
    useState(true);

  useEffect(() => {
    if (splashScreen.enabled) {
      const splashTimer = setTimeout(
        () => setIsShowingSplashAnimation(false),
        splashScreen.duration
      );
      return () => {
        clearTimeout(splashTimer);
      };
    }
  }, []);

  const changeTheme = () => {
    setIsDark(!isDark);
  };

  return (
    <div className={isDark ? "dark-mode" : null}>
      <StyleProvider value={{isDark: isDark, changeTheme: changeTheme}}>
        {/* {isShowingSplashAnimation && splashScreen.enabled ? (
          <SplashScreen />
        ) : ( */}
          <>
            <Header />
            <Fade bottom duration={1000} distance="20px">
              <div className="main contact-margin-top" id="contact">
                <div>
                  <h1>Terms of Service</h1>

                  <h3>1. Acceptance of Terms</h3>
                  <p>
                    By accessing or using our services at IT Spar, you
                    agree to be bound by these Terms of Service. If you do not
                    agree to these Terms, you may not access or use our
                    services.
                  </p>

                  <h3>2. Service Description</h3>
                  <p>
                    Our services include [describe the IT services or products
                    provided by the company].
                  </p>

                  <h3>3. User Responsibilities</h3>
                  <p>When using our services, you agree to:</p>
                  <ul>
                    <li>Comply with all applicable laws and regulations.</li>
                    <li>
                      Provide accurate and up-to-date information during
                      registration or when requested.
                    </li>
                    <li>
                      Maintain the confidentiality and security of your account
                      credentials.
                    </li>
                    <li>
                      Use our services for lawful purposes only and not engage
                      in any illegal activities.
                    </li>
                    <li>Respect the intellectual property rights of others.</li>
                  </ul>

                  <h3>4. Intellectual Property</h3>
                  <p>
                    The content, logos, trademarks, and other materials used in
                    connection with our services are owned or licensed by us and
                    are protected by intellectual property laws. You may not
                    use, reproduce, modify, or distribute our intellectual
                    property without prior written consent.
                  </p>

                  <h3>5. Limitation of Liability</h3>
                  <p>
                    In no event shall IT Spar, its directors, officers,
                    employees, or affiliates be liable for any direct, indirect,
                    incidental, special, or consequential damages resulting from
                    the use or inability to use our services, including but not
                    limited to any errors, interruptions, or loss of data.
                  </p>

                  <h3>6. Privacy</h3>
                  <p>
                    Our Privacy Policy governs the collection, use, and
                    disclosure of personal information provided by you. By using
                    our services, you consent to the collection and use of your
                    personal information as described in our Privacy Policy.
                  </p>

                  <h3>7. Termination</h3>
                  <p>
                    We reserve the right to suspend or terminate your access to
                    our services at any time.
                  </p>
                </div>
              </div>
            </Fade>
            <Footer />
            <ScrollToTopButton />
          </>
        // )}
      </StyleProvider>
    </div>
  );
};

export default Main;
