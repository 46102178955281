import React, {useEffect, useState} from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
// import ScrollToTopButton from "./topbutton/Top";
import Profile from "../containers/profile/Profile";
import SplashScreen from "../containers/splashScreen/SplashScreen";
import {splashScreen} from "../portfolio";
import {StyleProvider} from "../contexts/StyleContext";
import {useLocalStorage} from "../hooks/useLocalStorage";
import ScrollToTopButton from "../containers/topbutton/Top";
import "../containers/Main.scss";
import {Fade} from "react-reveal";

const Main = () => {
  const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
  const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);
  const [isShowingSplashAnimation, setIsShowingSplashAnimation] =
    useState(true);

  useEffect(() => {
    if (splashScreen.enabled) {
      const splashTimer = setTimeout(
        () => setIsShowingSplashAnimation(false),
        splashScreen.duration
      );
      return () => {
        clearTimeout(splashTimer);
      };
    }
  }, []);

  const changeTheme = () => {
    setIsDark(!isDark);
  };

  return (
    <div className={isDark ? "dark-mode" : null}>
      <StyleProvider value={{isDark: isDark, changeTheme: changeTheme}}>
        {/* {isShowingSplashAnimation && splashScreen.enabled ? (
          <SplashScreen />
        ) : ( */}
        <>
          <Header />
          <Fade bottom duration={1000} distance="20px">
          <div className="main contact-margin-top" id="contact">
              <h1>Cancellation & Refund Policy</h1>

              <h2>1. Cancellation</h2>
              <p>
                If you wish to cancel your subscription or service with [Company
                Name], please contact our customer support team at [contact
                email address]. Cancellations will be processed according to the
                terms specified in your service agreement or subscription plan.
              </p>

              <h2>2. Refunds</h2>
              <p>Refunds may be provided in the following circumstances:</p>
              <ul>
                <li>
                  If you cancel your subscription within the specified refund
                  period as mentioned in your service agreement or subscription
                  plan
                </li>
                <li>
                  If we fail to deliver the agreed-upon services as outlined in
                  the service agreement
                </li>
                <li>If a refund is required by law</li>
              </ul>

              <h2>3. Refund Process</h2>
              <p>
                To request a refund, please contact our customer support team at
                [contact email address] and provide the necessary details,
                including your order or subscription information. Refunds will
                be processed within [number of days] and will be credited to the
                original payment method used for the purchase.
              </p>

              <h2>4. Non-Refundable Items</h2>
              <p>
                There are certain circumstances where refunds will not be
                provided:
              </p>
              <ul>
                <li>
                  If the refund request is made after the specified refund
                  period
                </li>
                <li>
                  If the service has been fully rendered or the subscription
                  period has already started
                </li>
                <li>
                  If the refund is requested due to reasons beyond our control,
                  such as changes in your business requirements or preferences
                </li>
              </ul>

              <h2>5. Contact Us</h2>
              <p>
                If you have any questions or concerns about our Cancellation &
                Refund Policy, please contact us at [contact email address]. We
                will be happy to assist you.
              </p>
            </div>
          </Fade>
          <Footer />
          <ScrollToTopButton />
        </>
        {/* )} */}
      </StyleProvider>
    </div>
  );
};

export default Main;
